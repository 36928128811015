<div class="left-panel">
  <app-parcel-status
    class="parcel-status-container"
    [parcelStatus]="tracking.parcelStatus.parcelStatusCustomerDesc"
    [parcelStatusIdNo]="tracking.parcelStatus.parcelStatusIdNo"
  ></app-parcel-status>
  <div class="card">
    <div class="card-header">
      <div>SENDER DETAILS</div>
      <div class="expand-collapse" (click)="expandSender()">
        <mat-icon
          class="expand-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="{{ this.sender ? 'horizontal_rule' : 'add' }}"
        >
        </mat-icon>
      </div>
    </div>
    <div *ngIf="!sender" class="collapse-end">
      <div class="card-info-row">
        <div *ngIf="tracking.inductionDatetime" class="card-info">
          <div class="sub-header">Date of drop off</div>
          <p class="card-info-text">
            <app-date-format
              [date]="tracking.inductionDatetime"
            ></app-date-format>
          </p>
        </div>
        <div *ngIf="tracking.originNode.nodeCode" class="card-info">
          <div class="sub-header">Location code</div>
          <p class="card-info-text">
            {{ tracking.originNode.nodeCode }}
          </p>
        </div>
      </div>
      <div *ngIf="tracking.originNode.nodeName" class="card-info-row">
        <div class="card-info">
          <div class="sub-header">Sent from</div>
          <p class="card-info-text">{{ tracking.originNode.nodeName }}</p>
        </div>
      </div>
      <div *ngIf="tracking.sendCustomerName" class="card-info">
        <div class="sub-header">Sender name</div>
        <p class="card-info-text">{{ tracking.sendCustomerName }}</p>
      </div>
    </div>
  </div>
  <div class="card">
    <div class="card-header">
      <div>RECIPIENT DETAILS</div>
      <div class="expand-collapse" (click)="expandRecipient()">
        <mat-icon
          class="expand-icon"
          aria-hidden="false"
          aria-label="Example home icon"
          fontIcon="{{ this.recipient ? 'horizontal_rule' : 'add' }}"
        >
        </mat-icon>
      </div>
    </div>
    <div *ngIf="!recipient">
      <div class="card-info-row">
        <div *ngIf="tracking.estimatedCustomerDeliveryDate" class="card-info">
          <div class="sub-header">Predicted arrival</div>
          <p class="card-info-text">
            <app-date-format
              [date]="tracking.estimatedCustomerDeliveryDate"
            ></app-date-format>
          </p>
        </div>
        <div class="card-info">
          <div class="sub-header">Location code</div>
          <p class="card-info-text">
            {{ tracking.destinationNode.nodeCode }}
          </p>
        </div>
      </div>
      <div class="card-info">
        <div class="sub-header">Arrive to</div>
        <p class="card-info-text">
          {{ tracking.destinationNode.nodeName }}
        </p>
      </div>
      <div *ngIf="tracking.rcvCustomerName" class="card-info">
        <div class="sub-header">Recipient name</div>
        <p class="card-info-text">{{ tracking.rcvCustomerName }}</p>
      </div>
    </div>
  </div>
</div>
