import { Component, Input } from '@angular/core';
import { SENDER } from '../../types/card-sender';

@Component({
  selector: 'app-sender-details',
  templateUrl: './sender-details.component.html',
  styleUrls: ['./sender-details.component.scss']
})
export class SenderDetailsComponent {
  @Input() tracking!: SENDER;
}
