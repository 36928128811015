import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-parcel-not-found',
  templateUrl: './parcel-not-found.component.html',
  styleUrls: ['./parcel-not-found.component.scss']
})
export class ParcelNotFoundComponent {
  @Input() trackingNumber!: string;
  @Input() query!: string;
}
