import { Component, Input } from '@angular/core';
import { RECIPIENT } from '../../types/card-recipient';
import { SENDER } from '../../types/card-sender';

@Component({
  selector: 'app-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss']
})
export class CardComponent {
  @Input() tracking!: { payload: SENDER[] };
}
