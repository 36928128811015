<div *ngFor="let item of tracking.payload">
    <div class="card">
        <div class="card-header">{{ item.cardHeader }}</div>
        <div class="card-info">
            <div class="sub-header">{{ item.dateDropOff }}</div>
            <p class="card-info-text">{{ item.dateDropOffText }}</p>
        </div>
        <div class="card-info">
            <div class="sub-header">{{ item.sendFrom }}</div>
            <p class="card-info-text">{{ item.sendFromText }}</p>
        </div>
        <div class="card-info">
            <div class="sub-header">{{ item.senderName }}</div>
            <p class="card-info-text">{{ item.senderNameText }}</p>
        </div>
    </div>
</div>