import { Component, Input } from '@angular/core';
import { parseISO } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

@Component({
  selector: 'app-date-format',
  templateUrl: './date-format.component.html',
  styleUrls: ['./date-format.component.scss']
})
export class DateFormatComponent {
  @Input() date!: string;
  formatDateTime(date: string) {
    const parsed = parseISO(date);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return formatInTimeZone(parsed, timeZone, 'dd MMM yyyy HH[H]mm').replace(/\[[^\[\]]*\]/g, 'H')
  }
}
