import { Component, OnInit } from '@angular/core';
import { TRACKING_RESPONSE } from './types/tracking-response';
import { TrackingService } from './services/tracking.service';
import { ActivatedRoute } from '@angular/router';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss'],
})
export class TrackingComponent implements OnInit {
  tracking!: TRACKING_RESPONSE;
  loading = false;
  notError = true;
  query = '';

  constructor(
    private trackingService: TrackingService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      if (params['id']) {
        this.loading = true;
        this.query = params['id'];
        this.trackingService
          .getTracking({
            customerRefCode: params['id'],
            customerVisibleOnly: 'Y',
          })
          .pipe(
            catchError((err) => {
              this.notError = false;
              return of([]);
            })
          )
          .subscribe((t) => {
            console.log(t);
            this.tracking = t;
            this.loading = false;
          });
      } else {
        this.notError = false;
        this.loading = false;
      }
    });
  }
}
