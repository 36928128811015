<div *ngIf="_tracking && _tracking.length > 0">
    <app-header
      class="tracking-header"
      [trackingNumber]="_query"
    ></app-header>
    <div *ngFor="let parcel of _tracking; let i = index" class="tracking-info">
      <app-left-panel [tracking]="_tracking[i]"></app-left-panel>
      <app-right-panel [parcelEvents]="_tracking[i].parcelEvents" ></app-right-panel>
    </div>
</div>
<div *ngIf="_loading">
    <app-loading-indicator></app-loading-indicator>
</div>
<div *ngIf="!_loading && _tracking?.length === 0">
  <app-parcel-not-found [query]="_query"></app-parcel-not-found>
</div>
<div *ngIf="!_query">
  <app-parcel-not-found [query]="_query"></app-parcel-not-found>
</div>