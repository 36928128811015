import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TRACK } from '../types/track-payload';
import { TRACKING_RESPONSE } from '../types/tracking-response';
import { environment } from '../../../environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class TrackingService {
  private apiUrl = environment.api_url;

  constructor(private http: HttpClient) { }
  getTracking(payload: TRACK): Observable<TRACKING_RESPONSE>  {
    return this.http.post<TRACKING_RESPONSE>(this.apiUrl, payload, httpOptions);
  }
}
