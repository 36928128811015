import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppComponent } from './app.component';
import { TrackingComponent } from './tracking/tracking.component';
import { CardComponent } from './tracking/components/card/card.component';
import { HeaderComponent } from './tracking/components/header/header.component';
import { SenderDetailsComponent } from './tracking/components/sender-details/sender-details.component';
import { RecipientDetailsComponent } from './tracking/components/recipient-details/recipient-details.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { TrackingDetailsComponent } from './tracking/components/tracking-details/tracking-details.component';
import { HttpClientModule } from '@angular/common/http';
import { TrackingScreenComponent } from './tracking/components/tracking-screen/tracking-screen.component';
import { RightPanelComponent } from './tracking/components/right-panel/right-panel.component';
import { LeftPanelComponent } from './tracking/components/left-panel/left-panel.component';
import { RouterModule, Routes } from '@angular/router';
import { DateFormatComponent } from './tracking/components/date-format/date-format.component';
import { LoadingIndicatorComponent } from './tracking/components/loading-indicator/loading-indicator.component';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import { ParcelNotFoundComponent } from './tracking/components/parcel-not-found/parcel-not-found.component';
import { ParcelStatusComponent } from './tracking/components/parcel-status/parcel-status.component';
import { ParcelStatusIconComponent } from './tracking/components/parcel-status-icon/parcel-status-icon.component';
import { MatCardModule } from '@angular/material/card';

const appRoutes: Routes = [
  { path: '', component: TrackingComponent },
];

@NgModule({
  declarations: [
    AppComponent,
    TrackingComponent,
    CardComponent,
    HeaderComponent,
    SenderDetailsComponent,
    RecipientDetailsComponent,
    TrackingDetailsComponent,
    TrackingScreenComponent,
    RightPanelComponent,
    LeftPanelComponent,
    DateFormatComponent,
    LoadingIndicatorComponent,
    ParcelNotFoundComponent,
    ParcelStatusComponent,
    ParcelStatusIconComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatIconModule,
    HttpClientModule,
    MatProgressSpinnerModule,
    MatCardModule,
    RouterModule.forRoot(appRoutes)
  ],
  exports: [
    RouterModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
